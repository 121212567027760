import React from 'react';
import { Box, Text, Icon, Flex, Table, Thead, Tbody, Tr, Th, Td, Button, Tag } from '@chakra-ui/react';
import { FaLink, FaCheck, FaClock, FaTimes, FaUndo, FaBan } from 'react-icons/fa';

const ManagePaymentLinks: React.FC = () => {
  const stats = [
    { label: 'Links gerados', value: 2, icon: FaLink },
    { label: 'Links pagos', value: 1, icon: FaCheck },
    { label: 'Pendentes', value: 1, icon: FaClock },
    { label: 'Não pagos', value: 0, icon: FaTimes },
    { label: 'Estornados', value: 0, icon: FaUndo },
    { label: 'Cancelados', value: 0, icon: FaBan },
  ];

  const paymentLinks = [
    { id: 1, description: 'Produto A', value: 'R$ 100,00', paymentMethods: 'PIX, Cartão', url: 'https://example.com', status: 'Pendente' },
    { id: 2, description: 'Produto B', value: 'R$ 200,00', paymentMethods: 'Boleto', url: 'https://example.com', status: 'Pago' },
  ];

  const getStatusColorScheme = (status: string) => {
    switch (status) {
      case 'Pago':
        return 'green';
      case 'Pendente':
        return 'yellow';
      case 'Não Pago':
        return 'red';
      default:
        return 'gray';
    }
  };

  return (
    <Box p={4}>
      <Flex wrap="wrap" gap={4} justify="space-between" mb={6}>
        {stats.map((stat) => (
          <Box
            key={stat.label}
            p={4}
            borderWidth="1px"
            borderRadius="md"
            boxShadow="md"
            display="flex"
            alignItems="center"
            gap={3}
            width="30%"
            backgroundColor="white"
          >
            <Icon as={stat.icon} boxSize={6} color="green" />
            <Text fontSize="lg" fontWeight="bold">
              {stat.label}: {stat.value}
            </Text>
          </Box>
        ))}
      </Flex>
      <Box overflowX="auto">
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>DESCRIÇÃO</Th>
              <Th>VALOR</Th>
              <Th>FORMAS DE PAGAMENTO</Th>
              <Th>URL</Th>
              <Th>SITUAÇÃO</Th>
              <Th>AÇÕES</Th>
            </Tr>
          </Thead>
          <Tbody>
            {paymentLinks.map(link => (
              <Tr key={link.id}>
                <Td>{link.description}</Td>
                <Td>{link.value}</Td>
                <Td>{link.paymentMethods}</Td>
                <Td>
                  <a href={link.url} target="_blank" rel="noopener noreferrer">{link.url}</a>
                </Td>
                <Td>
                  <Tag colorScheme={getStatusColorScheme(link.status)}>{link.status}</Tag>
                </Td>
                <Td>
                  <Button size="sm" colorScheme="blue" mr={2}>Editar</Button>
                  <Button size="sm" colorScheme="red">Excluir</Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default ManagePaymentLinks;
