import React from 'react';
import { Box, SimpleGrid, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import NewPaymentLink from './components/NewPaymentLink';
import ManagePaymentLinks from './components/ManagePaymentLinks';

export default function PayementLinkMenu() {
	return (
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
			<SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px'>
        <Tabs isLazy isFitted variant='enclosed'>
          <TabList>
            <Tab _selected={{ color: '#0D3323', fontWeight: 'bold' }}>Novo Link de Pagamento</Tab>
            <Tab _selected={{ color: '#0D3323', fontWeight: 'bold' }}>Gerenciar Links de Pagamento</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <NewPaymentLink />
            </TabPanel>
            <TabPanel>
              <ManagePaymentLinks />
            </TabPanel>
          </TabPanels>
        </Tabs>
			</SimpleGrid>
		</Box>
	);
}
