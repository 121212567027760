import axios from "./api";
import { GetUserInterface, UpdateBankingAccount, UpdateLegaEntity, UpdateLegaEntityDoc, UpdateSellerDocuments, UpdatePersonalDoc, UpdatePersonalData } from "models/User";
import { GetMembershipPlans, CreateContract } from "models/MembershipPlans";
import { useUserStore } from "store/reducers/user"
import { notifyError, notifySuccess } from 'utils/notification';


export const updatePersonalData = async (params: UpdatePersonalData.Body): Promise<UpdatePersonalData.Result> => {
  const token = useUserStore.getState().auth.token;
  const adminUrl = `/v1/users/personal-data`;
  try {

    const balance = await axios.put(
      `${process.env.REACT_APP_API_URL}${adminUrl}`,
      params,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );

    return balance.data as UpdatePersonalData.Result;
  } catch (err) {
    console.log(err);
  }
};

export const getUserRequest = async (): Promise<GetUserInterface.Result> => {
  const token = useUserStore.getState().auth.token;
  const adminUrl = `/v1/users`;
  try {

    const balance = await axios.get(
      `${process.env.REACT_APP_API_URL}${adminUrl}`,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );

    return balance.data as GetUserInterface.Result;
  } catch (err) {
    console.log(err);
  }
};

export const updateOnboardingStep = async (params: { onboarding_step: number, type?: "cpf" | "cnpj" }): Promise<GetUserInterface.Result> => {
  const token = useUserStore.getState().auth.token;
  const adminUrl = `/v1/users/onboarding-step`;
  try {

    const balance = await axios.put(
      `${process.env.REACT_APP_API_URL}${adminUrl}`,
      {
        "onboarding_step": params.onboarding_step,
        "type": params.type
      },
      {
        headers: {
          'Authorization': `Bearer ${token}`
        },

      },

    );

    return balance.data as GetUserInterface.Result;
  } catch (err) {
    console.log(err);
  }
};

export const updateBankingAccount = async (body: UpdateBankingAccount.Body): Promise<UpdateBankingAccount.Result> => {
  const token = useUserStore.getState().auth.token;
  const adminUrl = `/v1/users/banking-account`;
  try {
    const result = await axios.put(
      `${process.env.REACT_APP_API_URL}${adminUrl}`,
      {
          holder_name: body.holder_name,
          bank_code: body.banco_id,
          agency: body.agencia,
          account: body.conta,
          pix_key: body.n_pix
      },
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );

    return result.data as UpdateBankingAccount.Result;
  } catch (err) {
    console.log(err);
  }
};

export const updateLegalEntity = async (body: UpdateLegaEntity.Body): Promise<UpdateLegaEntity.Result> => {
  const token = useUserStore.getState().auth.token;
  const adminUrl = `/v1/users/entity-data`;
  try {
    const result = await axios.put(
      `${process.env.REACT_APP_API_URL}${adminUrl}`,
      {
        cnpj: body.cnpj,
        name: body.cnpj_razao,
        business_name: body.cnpj_social,
        zipcode: body.cnpj_zipcode,
        address: body.cnpj_address,
        city: body.cnpj_city,
        state: body.cnpj_state,
        neighborhood: body.neighborhood,
        complement: body.cnpj_complement,
        number: body.cnpj_number,
        cellphone: body.cellphone,
        email: body.email
      },
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );

    return result.data as UpdateLegaEntity.Result;
  } catch (err) {
    console.log(err);
  }
};

export const updateLegalEntityDoc = async (body: UpdateLegaEntityDoc.Documents): Promise<UpdateLegaEntity.Result> => {
  const token = useUserStore.getState().auth.token;
  const adminUrl = `/v1/users/entity-document`;

  const formData = new FormData();

    body.cnpj_certidao && formData.append("cnpj_certidao", body.cnpj_certidao);
    body.cnpj_doc_mei && formData.append("cnpj_doc_mei", body.cnpj_doc_mei);

  try {
    const result = await axios.post(
      `${process.env.REACT_APP_API_URL}${adminUrl}`,
      formData,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          "Content-Type": "multipart/form-data"
        }
      }
    );

    return result.data as UpdateLegaEntity.Result;
  } catch (err) {

    return {
      success: false,
      data: null,
      message: ""
    }
    console.log(err);
  }
};

export const updatePersonalDoc = async (body: UpdatePersonalDoc.Body): Promise<UpdateLegaEntity.Result> => {
  const token = useUserStore.getState().auth.token;
  const adminUrl = `/v1/users/personal-document`;

  const formData = new FormData();

  body.foto_doc_pessoal_frente && formData.append("foto_doc_pessoal_frente", body.foto_doc_pessoal_frente);
  body.foto_doc_pessoal_verso && formData.append("foto_doc_pessoal_verso", body.foto_doc_pessoal_verso);
  body.foto_rosto_frente && formData.append("foto_rosto_frente", body.foto_rosto_frente);
  body.comprovante_endereco && formData.append("comprovante_endereco", body.comprovante_endereco);

  try {
    const result = await axios.post(
      `${process.env.REACT_APP_API_URL}${adminUrl}`,
      formData,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          "Content-Type": "multipart/form-data"
        }
      }
    );

    return result.data as UpdateLegaEntity.Result;
  } catch (err) {
    console.log(err);
  }
};

export const getMembershipPlans = async (): Promise<GetMembershipPlans.Result> => {
  const token = useUserStore.getState().auth.token;
  const adminUrl = `/v1/users/membership-plans`;

  try {
    const result = await axios.get(
      `${process.env.REACT_APP_API_URL}${adminUrl}`,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      }
    );

    return result.data as GetMembershipPlans.Result;
  } catch (err) {
    console.log(err);
  }
};

export const createContract = async (params: CreateContract.Params): Promise<CreateContract.Result> => {
  const token = useUserStore.getState().auth.token;
  const adminUrl = `/v1/users/contract`;

  try {
    const result = await axios.post(
      `${process.env.REACT_APP_API_URL}${adminUrl}`,
      params,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      }
    );

    return result.data as GetMembershipPlans.Result;
  } catch (err) {
    console.log(err);
  }
};

export const completeRegistration = async (): Promise<boolean> => {
  const token = useUserStore.getState().auth.token;
  const adminUrl = `/v1/users/complete-registration`;

  try {
    const result = await axios.put(
      `${process.env.REACT_APP_API_URL}${adminUrl}`,
      {},
      {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      }
    );

    return true

  } catch (err) {
    console.log(err);
  }
};

export const changeRegistration = async (): Promise<boolean> => {
  const token = useUserStore.getState().auth.token;
  const adminUrl = `/v1/users/change-registration`;

  try {
    const result = await axios.put(
      `${process.env.REACT_APP_API_URL}${adminUrl}`,
      {},
      {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      }
    );

    return true

  } catch (err) {
    console.log(err);
  }
};



export const updateSellerDocuments = async (body:
  {
    type: "foto_doc_pessoal_frente"| "foto_doc_pessoal_verso" | "foto_rosto_frente" | "comprovante_endereco" | "cnpj_doc_mei"| "cnpj_certidao" | "faturamento",
    file: any
  }): Promise<UpdateSellerDocuments.Result> => {

  const token = useUserStore.getState().auth.token;
  const adminUrl = `/v1/users/seller-documents`;

  const formData = new FormData();

  const documentTypeEnum = {
    'cnpj_doc_mei':'cnpj_document',
    'cnpj_certidao':'cnpj_certificate',
    'foto_doc_pessoal_frente':'document_front',
    'foto_doc_pessoal_verso':'document_back',
    'foto_rosto_frente':'selfie_with_document',
    'comprovante_endereco':'proof_of_address',
    'faturamento':'billing_statement'
  }

  const documentType = documentTypeEnum[body.type]

  formData.append("type", documentType);
  formData.append(documentType, body.file);


  try {
    const result = await axios.post(
      `${process.env.REACT_APP_API_URL}${adminUrl}`,
      formData,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          "Content-Type": "multipart/form-data"
        }
      }
    );

    return result.data as UpdateSellerDocuments.Result;
  } catch (err) {
    console.log(err);
  }
};

export const getApiToken = async (): Promise<string> => {
  const token = useUserStore.getState().auth.token;
  const url = `/integration/get-token`;
  try {
    const result = await axios.get(
      `${process.env.REACT_APP_API_URL}${url}`,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      }
    );

    return result.data.data.token as string;
  } catch (err) {
    notifyError("Erro ao buscar token de integração");
  }
}

export const generateApiToken = async (): Promise<string> => {
  const token = useUserStore.getState().auth.token;
  const url = `/integration/generate-token`;

  const result = await axios.post(
    `${process.env.REACT_APP_API_URL}${url}`,
    {},
    {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    }
  );

  return result.data.data.token as string;
}

export const editProfileSeller = async (params: {
  name: string,
  cpf: string,
  birth_date: string | null,
  cep: string,
  address: string,
  city: string,
  state: string,
  complement: string,
  number: string
}): Promise<any> => {
  const token = useUserStore.getState().auth.token;
  const adminUrl = `/v1/users/edit-profile-seller-front`;

  try {
    const result = await axios.post(
      `${process.env.REACT_APP_API_URL}${adminUrl}`,
      params,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );

    notifySuccess("Seus dados foram editados!");
    return result.data;
  } catch (err) {
    console.log(err);
    notifyError("Ocorreu um erro ao tentar editar seus dados. Confira os dados e tente novamente.");
  }
};
