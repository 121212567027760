import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box, Button, Divider, FormControl, FormLabel, Input, Text, Switch, NumberInput, NumberInputField,
  NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, Select, HStack, useClipboard
} from '@chakra-ui/react';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import useStores from 'hooks/stores';
import SelectInput from 'components/form/SelectInput';

export default function NewPaymentLink() {
  const {
    register, handleSubmit, setValue, watch, formState: { errors }, reset,
  } = useForm();

  const {stores, isLoadingStores} = useStores();
  const [products, setProducts] = useState([]);
  const [selectedStore, setSelectedStore] = useState('');
  const [selectedProduct, setSelectedProduct] = useState(null);
  const { onCopy, hasCopied } = useClipboard(watch('link'));

  // Fetch stores when component mounts
  React.useEffect(() => {
    if (selectedStore && stores) {
      setValue('store_id', selectedStore);
    }
  }, [stores]);

  // Fetch products based on selected store
  useEffect(() => {
    const fetchProducts = async (storeId) => {
      //const token = useUserStore.getState().auth.token; // Assumindo que useUserStore tem o token
      // try {
      //   const response = await axios.get(`${process.env.REACT_APP_API_URL}/stores/${storeId}/products`, {
      //     headers: {
      //       Authorization: `Bearer ${token}`,
      //     },
      //   });
      //   setProducts(response.data);
      // } catch (error) {
      //   console.error('Error fetching products:', error);
      // }
    };

    if (selectedStore) {
      fetchProducts(selectedStore);
    } else {
      setProducts([]);
    }
  }, [selectedStore]);

  // Update product details when selected
  useEffect(() => {
    if (selectedProduct) {
      const product = products.find(p => p.id === selectedProduct);
      if (product) {
        setValue('title', product.name);
        setValue('amount', product.price.toFixed(2));
      }
    }
  }, [selectedProduct, products, setValue]);

  const generateRandomLink = () => {
    const randomLink = `https://meulink.com/${uuidv4()}`;
    setValue('link', randomLink);
  };

  const onSubmit = (data) => {
    console.log('Form data:', data);
  };

  const handleCancel = () => {
    reset(); // Reseta o formulário
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Dados Principais */}
        <Text fontSize="xl" fontWeight="bold">Dados Principais</Text>
        <Text>Defina o produto e o valor do que será vendido em seu link de pagamento.</Text>

        <FormControl mt={4} isInvalid={!!errors.store}>
          <SelectInput
                  register={register}
                  id="store_id"
                  label="Loja *"
                  options={stores?.data.map(item => (
                    { value: item.id.toString(), label: item.name }
                  ))}
                  placeholder="Selecione uma loja"
                />
        </FormControl>

        <FormControl mt={4} isInvalid={!!errors.product}>
          <FormLabel>Selecione o Produto *</FormLabel>
          <Select {...register('product', { required: true })} onChange={(e) => setSelectedProduct(e.target.value)} disabled={!selectedStore}>
            <option value="">Selecione um produto</option>
            {products.map((product) => (
              <option key={product.id} value={product.id}>{product.name}</option>
            ))}
          </Select>
        </FormControl>

        <FormControl mt={4} isInvalid={!!errors.link}>
          <FormLabel>Gerar código de link *</FormLabel>
          <Input {...register('link', { required: true })} placeholder="Link gerado" isReadOnly />
          <Button mt={2} colorScheme="green" onClick={generateRandomLink} disabled={!selectedStore || !selectedProduct}>
            Gerar Link
          </Button>
          <Button mt={2} ml={4} onClick={onCopy}>
            {hasCopied ? "Link Copiado" : "Copiar Link"}
          </Button>
        </FormControl>

        <FormControl mt={4}>
          <FormLabel>Título do Produto *</FormLabel>
          <Input {...register('title')} isReadOnly placeholder="Título do Produto" />
        </FormControl>

        <FormControl mt={4}>
          <FormLabel>Valor *</FormLabel>
          <NumberInput min={1} precision={2} isReadOnly>
            <NumberInputField {...register('amount')} placeholder="Valor" />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>

        {/* Configurações Avançadas */}
        <Text fontSize="xl" fontWeight="bold" mt={6}>Configurações Avançadas</Text>
        <Text>Defina o produto e o valor do que será vendido em seu link de pagamento.</Text>

        <FormControl mt={4} isInvalid={!!errors.paymentMethod}>
          <FormLabel>Meio de pagamento *</FormLabel>
          <Select {...register('paymentMethod', { required: true })}>
            <option value="pix">PIX</option>
            <option value="credit_card">Cartão de Crédito</option>
            <option value="boleto">Boleto</option>
          </Select>
        </FormControl>

        <FormControl display="flex" alignItems="center" mt={4}>
          <FormLabel htmlFor="address">Solicitar endereço</FormLabel>
          <Switch id="address" {...register('requestAddress')} />
        </FormControl>

        <FormControl display="flex" alignItems="center" mt={4}>
          <FormLabel htmlFor="phone">Solicitar telefone</FormLabel>
          <Switch id="phone" {...register('requestPhone')} />
        </FormControl>

        {/* Configurações do PIX */}
        <Text fontSize="lg" fontWeight="bold" mt={6}>Configurações do PIX</Text>
        <FormControl display="flex" alignItems="center" mt={4}>
          <FormLabel htmlFor="pixEnabled">PIX habilitado</FormLabel>
          <Switch id="pixEnabled" {...register('pixEnabled')} />
        </FormControl>
        <FormControl mt={4}>
          <FormLabel>Dias para expiração *</FormLabel>
          <NumberInput min={1} max={30} defaultValue={2}>
            <NumberInputField {...register('pixExpirationDays', { required: true })} />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>

        {/* Configurações do Cartão de Crédito */}
        <Text fontSize="lg" fontWeight="bold" mt={6}>Configurações do Cartão de Crédito</Text>
        <FormControl display="flex" alignItems="center" mt={4}>
          <FormLabel htmlFor="cardEnabled">Cartão habilitado</FormLabel>
          <Switch id="cardEnabled" {...register('cardEnabled')} />
        </FormControl>
        <FormControl mt={4}>
          <FormLabel>Número de parcelas *</FormLabel>
          <NumberInput min={1} max={12} defaultValue={12}>
            <NumberInputField {...register('installments', { required: true })} />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>

        {/* Configurações do Boleto */}
        <Text fontSize="lg" fontWeight="bold" mt={6}>Configurações do Boleto</Text>
        <FormControl display="flex" alignItems="center" mt={4}>
          <FormLabel htmlFor="boletoEnabled">Boleto habilitado</FormLabel>
          <Switch id="boletoEnabled" {...register('boletoEnabled')} />
        </FormControl>
        <FormControl mt={4}>
          <FormLabel>Dias para expiração *</FormLabel>
          <NumberInput min={1} max={30} defaultValue={2}>
            <NumberInputField {...register('boletoExpirationDays', { required: true })} />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>

        {/* Botões de Ação */}
        <HStack mt={6}>
          <Button colorScheme="blue" type="submit">Criar Link de Pagamento</Button>
          <Button colorScheme="red" onClick={handleCancel}>Cancelar</Button>
        </HStack>
      </form>
      <Divider my={6} />
    </Box>
  );
}
