import React from 'react';
import { Box, SimpleGrid, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import Pedidos from './Pedidos';
import AbandonedCart from './AbandonedCart';

export default function MenuPedidos() {
	return (
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
			<SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px'>
        <Tabs isLazy isFitted variant='enclosed'>
          <TabList>
            <Tab _selected={{ color: '#0D3323', fontWeight: 'bold' }}>Pedidos</Tab>
            <Tab _selected={{ color: '#0D3323', fontWeight: 'bold' }}>Carrinho Abandonado</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Pedidos />
            </TabPanel>
            <TabPanel>
              <AbandonedCart />
            </TabPanel>
          </TabPanels>
        </Tabs>
			</SimpleGrid>
		</Box>
	);
}
