import React from 'react';
import { Checkbox, FormLabel, WrapItem } from '@chakra-ui/react';

type CheckboxInputProps = {
  register: Function
  id: string
  label: string
}

export default function CheckboxInput ({ register, id, label }: CheckboxInputProps) {
  return (
    <WrapItem alignItems="center">
      <Checkbox
        {...register(id)}
        colorScheme="green"
        id={id}
      />
      <FormLabel htmlFor={id} marginBottom={0} marginLeft={1} cursor="pointer" fontWeight='normal' color='gray.600' fontSize='15px'>
        {label}
      </FormLabel>
    </WrapItem>
  );
};
