import React from 'react';
import { Checkout, CreateCheckoutRequest } from 'models/checkout';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import LoadingPage from 'components/Loading/LoadingPage';
import { useUserStore } from 'store/reducers/user';
import { useForm } from 'react-hook-form';
import { createCheckout, updateCheckout } from 'controllers/Checkout';
import CheckboxInput from 'components/form/CheckboxInput';
import { ImageInput } from 'components/form/ImageInput';
import SelectInput from 'components/form/SelectInput';
import { notifyError, notifySuccess } from 'utils/notification';
import useStores from 'hooks/stores';
import { filterImages } from 'utils/form';

type CheckoutFormProps = {
  switchShow: Function
  show: boolean
  handleGetCheckouts: Function
  selected?: Checkout
}

export default function CheckoutForm({switchShow, show, handleGetCheckouts, selected}: CheckoutFormProps) {
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const token = useUserStore((state) => state.auth.token);
  const [loading, setLoading] = React.useState(false);

  const { stores} = useStores();

  const defaultValues = selected ? {
    store_id: selected.store.id,
    payment_via_bank_slip_activated: selected.payment_via_bank_slip_activated,
    payment_via_card_activated: selected.payment_via_card_activated,
    payment_via_pix_activated: selected.payment_via_pix_activated,
    store_logo: selected.store_logo,
    show_benefits: selected.show_benefits,
    show_timers: selected.show_timers,
    show_info_on_footer: selected.show_info_on_footer,
  } : {
    show_benefits: true,
    show_timers: true,
    show_info_on_footer: true,
  }

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    watch
  } = useForm<CreateCheckoutRequest>({ defaultValues });
  const logo = watch('logo');

  const handleSaveCheckout = async (data: CreateCheckoutRequest) => {
    data.logo = filterImages(logo);

    if (selected) {
      await handleUpdateCheckout(data);
    } else {
      await handleCreateCheckout(data);
    }
  }

  const handleCreateCheckout = async (data: CreateCheckoutRequest) => {
    setLoading(true);

    try {
      const createCheckoutData: CreateCheckoutRequest = {
        ...data,
        store_id: Number(data.store_id),
      };

      await createCheckout(createCheckoutData, token).finally(() => handleGetCheckouts());
      notifySuccess('Checkout criado com sucesso!');
      switchShow();
    } catch (error) {
      notifyError('Erro ao criar o checkout. Tente Novamente!');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateCheckout = async (data: CreateCheckoutRequest) => {
    setLoading(true);

    try {
      const checkoutData: CreateCheckoutRequest = {
        ...data,
        store_id: Number(data.store_id),
        store_logo: data.store_logo
      };

      await updateCheckout(selected.id, checkoutData, token).finally(() => handleGetCheckouts());

      switchShow();
      notifySuccess('Checkout atualizado com sucesso!');
    } catch (error) {
      notifyError('Erro ao atualizar o checkout. Tente Novamente!');
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (selected && stores) {
      setValue('store_id', selected.store.id);
    }
  }, [stores]);

  React.useEffect(() => {
    reset();
  }, [show]);

  return (
    <>
      <LoadingPage isOpen={loading} />

      <Card flexDirection='column' marginTop={'10px'} w='100%' px='20px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
        <Box pt='10px'>
          <Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>
            Configurar Checkout
          </Text>
          <form onSubmit={handleSubmit(handleSaveCheckout)}>
            <FormControl rowGap='20px' mt='20px'>
              <SelectInput
                register={register}
                id="store_id"
                label="Loja *"
                options={stores?.data.map(item => (
                  { value: item.id.toString(), label: item.name }
                ))}
                placeholder="Selecione uma loja"
              />

              <Box mb='10px'>
                <FormLabel>Customização</FormLabel>
                <CheckboxInput register={register} id='show_benefits' label='Exibir benefícios' />
                <CheckboxInput register={register} id='show_timers' label='Exibir Timer' />
                <CheckboxInput register={register} id='show_info_on_footer' label='Exibir informações da loja no rodapé' />
              </Box>

              <Box mb='10px'>
                <FormLabel>Formas de Pagamento</FormLabel>
                <CheckboxInput register={register} id='payment_via_card_activated' label='Pagamento por cartão ativado' />
                <CheckboxInput register={register} id='payment_via_bank_slip_activated' label='Pagamento por boleto ativado' />
                <CheckboxInput register={register} id='payment_via_pix_activated' label='Pagamento por PIX ativado' />
              </Box>

              {selected ? (
                <ImageInput
                  control={control}
                  id='logo'
                  label='Logomarca'
                  required={false}
                  images={logo ? logo: [{preview: selected.store_logo}]}
                />
              ) : (
                <ImageInput
                  control={control}
                  id='logo'
                  label='Logomarca'
                  images={logo}
                />
              )}

              <Button type='submit'>Salvar</Button>
              <Button onClick={() => switchShow()}>Voltar</Button>
            </FormControl>
          </form>
        </Box>
      </Card>
    </>
  );
}
