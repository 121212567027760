import { Product, ProductType } from '../models/Product';

export const removeVariationImagesFromImages = (product: Product) => {
  return product.images.filter(image => {
    return product.variations.every(variation => {
      return variation.image_id !== image.id;
    });
  });
}

export function getProductType(type: string) {
  switch (type) {
    case ProductType.PHYSICAL:
      return "Físico";
    case ProductType.DIGITAL:
      return "Digital";
    default:
      return "-";
  }
}

export function getProductSource(source: string) {
  switch (source) {
    case "podpay":
      return "Podpay";
    case "shopify":
      return "Shopify";
    default:
      return "Outro";
  }
}

export function getProductSourceColor(status: string) {
  switch (status) {
    case "podpay":
      return "#0D3323";
    case "shopify":
      return "#95bf47";
    default:
      return "#805ad5";
  }
}
