import * as React from "react";
import {
  Tag,
  Box,
  Flex,
  Icon,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Center,
  Button,
} from "@chakra-ui/react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  CellContext,
} from "@tanstack/react-table";

import Card from "components/card/Card";
import { Order, OrderResponse } from "models/Order";
import { formatCurrency } from "utils/currency";
import { formatDate, formatTime } from "utils/time";
import { ListOptionsMenu } from "components/menu/ListOptionsMenu";
import OrderFilters from "./OrderFilters";
import PaginationButtons from "components/paginationButtons/PaginationButtons";
import ShowFilters from "components/filter/ShowFilters";
import {
  getOrderColor,
  getOrderStatus,
  getPaymentType,
  getPaymentTypeIcon,
} from "utils/order";
import { OrderReportModal, } from "components/modal/OrderReportModal";
import { ImportExcelModal } from "components/modal/ImportExcelModal";

const columnHelper = createColumnHelper<Order>();

type CheckTableProps = {
  tableData: OrderResponse;
  tableName: any;
  setFilters: Function;
  showDetails: Function;
  page: number;
  setPage: Function;
};

export default function CheckTable({
  tableData,
  tableName,
  setFilters,
  showDetails,
  page,
  setPage,
}: CheckTableProps) {
  const [showFilters, setShowFilters] = React.useState<boolean>(false);
  const [reportModalOpen, setReportModalOpen] = React.useState<boolean>(false);
  const [importModalOpen, setImportModalOpen] = React.useState<boolean>(false);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const columns = [
    columnHelper.accessor("order_number", {
      id: "order_number",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Número do Pedido
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue() ?? "-"}
        </Text>
      ),
    }),
    columnHelper.accessor("transaction.type.name", {
      id: "transaction.type.name",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Tipo
        </Text>
      ),
      cell: (info) => (
        <Icon
          as={getPaymentTypeIcon(info.getValue())}
          w="24px"
          h="auto"
          color="green"
          title={getPaymentType(info.getValue())}
        />
      ),
    }),
    columnHelper.accessor("processed_at", {
      id: "processed_at",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Data da Venda
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {formatDate(info.getValue())} - {formatTime(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("total_price", {
      id: "total_price",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Valor
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {formatCurrency(parseFloat(info.getValue()))}
        </Text>
      ),
    }),
    columnHelper.accessor("customer.first_name", {
      id: "customer.first_name",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Comprador
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue() || "-"}
        </Text>
      ),
    }),
    columnHelper.accessor("store.name", {
      id: "store.name",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Loja
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("transaction.status.name", {
      id: "transaction.status.name",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Status
        </Text>
      ),
      cell: (info) => (
        <Center>
          <Tag colorScheme={getOrderColor(info.getValue())} size={"md"}>
            {getOrderStatus(info.getValue())}
          </Tag>
        </Center>
      ),
    }),
    {
      id: "options",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Opções
        </Text>
      ),
      cell: (info: CellContext<Order, string>) => (
        <ListOptionsMenu data={info.row.original} showDetails={showDetails} />
      ),
    },
  ];

  const data = tableData?.data;

  const table = useReactTable({
    data,
    columns,
    state: {
      // sorting,
      // columnFilters: []
    },
    // onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    // getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <OrderReportModal
        isOpen={reportModalOpen}
        onClose={() => setReportModalOpen(false)}
      />
      <ImportExcelModal
        isOpen={importModalOpen}
        onClose={() => setImportModalOpen(false)}
      />
      <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          {tableName}
        </Text>
        <Box>
          <Button
            variant={"solid"}
            colorScheme={"green"}
            mr="10px"
            onClick={() => setImportModalOpen(true)}
          >
            Importar
          </Button>
          <Button
            variant={"solid"}
            colorScheme={"blue"}
            onClick={() => {
              setReportModalOpen(true);
            }}
            mr="10px"
          >
            Gerar Relatório
          </Button>
          <ShowFilters
            showFilters={showFilters}
            setShowFilters={setShowFilters}
          />
        </Box>
      </Flex>
      <OrderFilters showFilters={showFilters} setFilters={setFilters} />
      {data && (
        <Box>
          <Table
            variant="simple"
            color="gray.500"
            mb="24px"
            justifyContent="center"
          >
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <Th
                        key={header.id}
                        colSpan={header.colSpan}
                        pe="10px"
                        borderColor={borderColor}
                        cursor="pointer"
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        <Flex
                          justifyContent="space-between"
                          align="center"
                          fontSize={{ sm: "10px", lg: "12px" }}
                          color="gray.400"
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: "",
                            desc: "",
                          }[header.column.getIsSorted() as string] ?? null}
                        </Flex>
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td
                          key={cell.id}
                          fontSize={{ sm: "14px" }}
                          minW={{ sm: "150px", md: "200px", lg: "auto" }}
                          borderColor="transparent"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>

          <PaginationButtons
            onClick={setPage}
            currentPage={page}
            lastPage={tableData?.meta?.last_page}
          />
        </Box>
      )}
    </Card>
  );
}
