import * as React from 'react';
import {
  Flex,
  Box,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Link
} from '@chakra-ui/react';

import {
  CellContext,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable
} from '@tanstack/react-table';

// Custom components
import Card from 'components/card/Card';
import { Checkout, CheckoutResponse } from 'models/checkout';
import { ListOptionsMenu } from 'components/menu/ListOptionsMenu';
import { ExternalLinkIcon } from '@chakra-ui/icons';

const columnHelper = createColumnHelper<Checkout>();

export default function CheckTable(props: { tableData: CheckoutResponse, tableName: any, select: Function, requestDelete: Function }) {
  const { tableData, tableName, select, requestDelete } = props;
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const brandColor = useColorModeValue("brand.500", "white");
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  let defaultData = tableData;

  const columns = [
    columnHelper.accessor('store.name', {
      id: 'store.name',
      header: () => (
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='gray.400'>
          Loja
        </Text>
      ),
      cell: (info) => (
        <Flex align='center'>
          <Text color={textColor} fontSize='sm' fontWeight='700'>
            {info.getValue()}
          </Text>
        </Flex>
      )
    }),
    columnHelper.accessor('payment_via_bank_slip_activated', {
      id: 'payment_via_bank_slip_activated',
      header: () => (
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='gray.400'>
          Pagamento via Boleto
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize='sm' fontWeight='700'>
          {info.getValue() ? "Sim" : "Não"}
        </Text>
      )
    }),
    columnHelper.accessor('payment_via_card_activated', {
      id: 'payment_via_card_activated',
      header: () => (
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='gray.400'>
          Pagamento via Cartão
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize='sm' fontWeight='700'>
          {info.getValue() ? "Sim" : "Não"}
        </Text>
      )
    }),
    columnHelper.accessor('payment_via_pix_activated', {
      id: 'payment_via_pix_activated',
      header: () => (
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='gray.400'>
          Pagamento via PIX
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize='sm' fontWeight='700'>
          {info.getValue() ? "Sim" : "Não"}
        </Text>
      )
    }),
    columnHelper.accessor('store_logo', {
      id: 'store_logo',
      header: () => (
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='gray.400'>
          Logomarca da Loja
        </Text>
      ),
      cell: (info) => (
        <Link href={info.getValue()} isExternal>
          <ExternalLinkIcon width="20px" height="20px" color={brandColor} />
        </Link>
      )
    }),
    {
      id: 'options',
      header: () => (
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='gray.400'>
          Opções
        </Text>
      ),
      cell: (info: CellContext<Checkout, string>) => (
        <ListOptionsMenu data={info.row.original} select={select} deleteRequest={requestDelete} />
      ),
    },
  ];

  const [data, setData] = React.useState(() => [...tableData.data]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      // columnFilters: []
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true
  });

  React.useEffect(() => {
    setData(() => [...tableData.data]);
  }, [tableData]);

  return (
    <Card flexDirection='column' w='100%' px='0px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
      <Flex px='25px' mb="8px" justifyContent='space-between' align='center'>
        <Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>
          {tableName}
        </Text>
      </Flex>
      <Box>
        <Table variant='simple' color='gray.500' mb='24px' mt="12px">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      pe='10px'
                      borderColor={borderColor}
                      cursor='pointer'
                      onClick={header.column.getToggleSortingHandler()}>
                      <Flex
                        justifyContent='space-between'
                        align='center'
                        fontSize={{ sm: '10px', lg: '12px' }}
                        color='gray.400'>
                        {flexRender(header.column.columnDef.header, header.getContext())}{{
                        asc: '',
                        desc: '',
                      }[header.column.getIsSorted() as string] ?? null}
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <Td
                        key={cell.id}
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        borderColor='transparent'>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
    </Card>
  );
}
