import React, { useEffect } from "react";

import {
  Box,
  FormControl,
  FormLabel,
  Button,
  Stack,
} from "@chakra-ui/react";

import { useUserStore } from 'store/reducers/user';
import { usePerfilPage } from "../../store/index";
import RenderFileInput from "./components/RenderFileInput"
import RenderImageInput from "./components/RenderImageInput"
import { GridContainer, GridItem } from "./styles"
import { useForm } from "react-hook-form"
import { DocumentData } from "../../models/types"
import { Row, TextCenter } from "../../styles";
import { Text as CustomText } from "styleguide/Typograph"
import { toastError, toastSuccess } from "styleguide/Toastify";
import { updateSellerDocuments } from "services/userRequests"

const PersonalDocumentsStep = ({ setFormIsValid, setFormInfo }) => {
  const MAX_FILE_SIZE = 2 * 1024 * 1024;
  const MAX_IMAGE_SIZE = 10 * 1024 * 1024;
  const { hasCnpj } = usePerfilPage();
  const [user] = useUserStore((state) => [state.user]);

  const { watch, setValue } =
    useForm<DocumentData>({
      defaultValues: {
        foto_doc_pessoal_frente: user?.foto_doc_pessoal_frente,
        foto_doc_pessoal_verso: user?.foto_doc_pessoal_verso,
        foto_rosto_frente: user?.foto_rosto_frente,
        comprovante_endereco: user?.comprovante_endereco,
        cnpj_doc_mei: user?.cnpj_doc_mei,
        cnpj_certidao: user?.cnpj_certidao,
        faturamento: user?.faturamento,
      },

    });
  const formData = watch()

  const validateFotoDocPessoalFrente = () => {
    return !!formData.foto_doc_pessoal_frente;
  };

  const validateFotoDocPessoalVerso = () => {
    return !!formData.foto_doc_pessoal_verso;
  };

  const validateFotoRostoFrente = () => {
    return !!formData.foto_rosto_frente;
  };

  const validateComprovanteEndereco = () => {
    return !!formData.comprovante_endereco;
  };

  const validateCnpjDocMei = () => {
    return !!formData.cnpj_doc_mei || !hasCnpj;
  };

  const validateCnpjCertidao = () => {
    return !!formData.cnpj_certidao || !hasCnpj;
  };
  const allowToSubmit = () => {
    // console.log({
    //   foto_doc_pessoal_frente: validateFotoDocPessoalFrente(),
    //   foto_doc_pessoal_verso: validateFotoDocPessoalVerso(),
    //   foto_rosto_frente: validateFotoRostoFrente(),
    //   comprovante_endereco: validateComprovanteEndereco(),
    //   cnpj_doc_mei: validateCnpjDocMei(),
    //   cnpj_certidao: validateCnpjCertidao(),
    // })

    return validateFotoDocPessoalFrente() &&
      validateFotoDocPessoalVerso() &&
      validateFotoRostoFrente() &&
      validateComprovanteEndereco() &&
      validateCnpjDocMei();
  }

  useEffect(() => {
    setFormIsValid(allowToSubmit());
    if (allowToSubmit()) {
      setFormInfo({
        foto_doc_pessoal_frente: formData?.foto_doc_pessoal_frente,
        foto_doc_pessoal_verso: formData?.foto_doc_pessoal_verso,
        foto_rosto_frente: formData?.foto_rosto_frente,
        comprovante_endereco: formData?.comprovante_endereco,
        cnpj_doc_mei: formData?.cnpj_doc_mei,
        cnpj_certidao: formData?.cnpj_certidao,
      })
    }
  }, [allowToSubmit()])
  const [modalshowsucesso, setModalsucesso] = React.useState(false);

  const auth = useUserStore((state) => state.auth);
  const [activeTab, setActiveTab] = React.useState(1);
  const [isLoading, setIsLoading] = React.useState(false);

  const [foto_rosto_frente, setFoto_rosto_frente] = React.useState(null);

  const [erro, setErro] = React.useState("");
  const [modalshowErro, setModalErro] = React.useState(false);


  const handleCnpjCertidaoChange = async (event: any) => {
    const selectedFilesetCnpj_certidao = event.target.files[0];
    if (selectedFilesetCnpj_certidao) {
      if (selectedFilesetCnpj_certidao.size > MAX_FILE_SIZE) {
        toastError('O tamanho do arquivo excede o limite permitido.');
        setModalErro(true);
      } else {
        try {
        const response = await updateSellerDocuments({
          type: "cnpj_certidao",
          file: selectedFilesetCnpj_certidao
        });

        if (response.success) {
          setValue("cnpj_certidao", selectedFilesetCnpj_certidao);
          // setCnpj_certidao(selectedFilesetCnpj_certidao);
          setModalsucesso(true)
        }
      } catch (err) {

        toastError("Erro para salvar o documento. Tente novamente mais tarde")

      }
      }
    } else {
      toastError('Você não selecionou nenhum arquivo!');
      setModalErro(true)
    }

  };

  const handleCnpjDocMEIChange = async (event: any) => {
    const selectedFilesetCnpj_doc_mei = event.target.files[0];


    if (selectedFilesetCnpj_doc_mei) {
      if (selectedFilesetCnpj_doc_mei.size > MAX_FILE_SIZE) {
        toastError('O tamanho do arquivo excede o limite permitido.');
        setModalErro(true);
      } else {
        try{
          const response = await updateSellerDocuments({
            type: "cnpj_doc_mei",
            file: selectedFilesetCnpj_doc_mei
          });

        if (response.success) {
          setValue("cnpj_doc_mei", selectedFilesetCnpj_doc_mei);
          toastSuccess("Documento MEI salvo com sucesso")
          setModalsucesso(true)
        }
      } catch (err) {

        toastError("Erro para salvar o documento. Tente novamente mais tarde")

      }
      }

    } else {
      toastError('Você não selecionou nenhum arquivo!');
      setModalErro(true)
    }


  };
  const handleFotoRostoFrenteChange = async (event: any) => {
    const selectedFilesetFoto_rosto_frente = event.target.files[0];
    if (selectedFilesetFoto_rosto_frente) {
      if (selectedFilesetFoto_rosto_frente.size > MAX_IMAGE_SIZE) {
        toastError('O tamanho do arquivo excede o limite permitido.');
        setModalErro(true);
      } else {
        try {
          const response = await updateSellerDocuments({
            type: "foto_rosto_frente",
            file: selectedFilesetFoto_rosto_frente
          });

        if (response.success) {
          setValue("foto_rosto_frente", selectedFilesetFoto_rosto_frente);
          toastSuccess("Documento salvo com sucesso")
          setModalsucesso(true)
        }
      } catch (err) {

        toastError("Erro para salvar o documento. Tente novamente mais tarde")

      }
      }

    } else {
      toastError('Você não selecionou nenhum arquivo!');
      setModalErro(true)
    }
  };

  const handleFotoDocPessoalVersoChange = async (event: any) => {
    const selectedFilesetFoto_doc_pessoal_verso = event.target.files[0];
    if (selectedFilesetFoto_doc_pessoal_verso) {
      if (selectedFilesetFoto_doc_pessoal_verso.size > MAX_IMAGE_SIZE) {
        toastError('O tamanho do arquivo excede o limite permitido.');
        setModalErro(true);
      } else {
        try{
          const response = await updateSellerDocuments({
            type: "foto_doc_pessoal_verso",
            file: selectedFilesetFoto_doc_pessoal_verso
          });

          if (response.success) {
            setValue("foto_doc_pessoal_verso", selectedFilesetFoto_doc_pessoal_verso);
            toastSuccess("Documento salvo com sucesso")
            setModalsucesso(true)
          }
        } catch (err) {

          toastError("Erro para salvar o documento. Tente novamente mais tarde")

        }
      }

    } else {
      toastError('Você não selecionou nenhum arquivo!');
      setModalErro(true)
    }
  };





  const handleFotoDocPessoalFrenteChange = async (event: any) => {
    const selectedFilesetFoto_doc_pessoal_frente = event.target.files[0];
    if (selectedFilesetFoto_doc_pessoal_frente) {
      if (selectedFilesetFoto_doc_pessoal_frente.size > MAX_IMAGE_SIZE) {
        toastError('O tamanho do arquivo excede o limite permitido.');
        setModalErro(true);
      } else {
        try{
          const response = await updateSellerDocuments({
            type: "foto_doc_pessoal_frente",
            file: selectedFilesetFoto_doc_pessoal_frente
          });

        if (response.success) {

          setValue("foto_doc_pessoal_frente", selectedFilesetFoto_doc_pessoal_frente);
          toastSuccess("Documento pessoal salvo com sucesso")
          setModalsucesso(true)
        }
      } catch (err) {

        toastError("Erro para salvar o documento. Tente novamente mais tarde")

      }
      }

    } else {
      toastError('Você não selecionou nenhum arquivo!');
      setModalErro(true)
    }
  };



  const handleDocEnderecoChange = async (event: any) => {
    const selectedFilesetComprovanteEndereco = event.target.files[0];
    if (selectedFilesetComprovanteEndereco) {
      if (selectedFilesetComprovanteEndereco.size > MAX_FILE_SIZE) {
        toastError('O tamanho do arquivo excede o limite permitido.');
        setModalErro(true);
      } else {
        try {
          const response = await updateSellerDocuments({
            type: "comprovante_endereco",
            file: selectedFilesetComprovanteEndereco
          });


        if (response.success) {
          setValue("comprovante_endereco", selectedFilesetComprovanteEndereco);
          toastSuccess("Comprovante salvo com sucesso")
          setModalsucesso(true)
        }
      } catch (err) {

        toastError("Erro para salvar o documento. Tente novamente mais tarde")

      }
      }

    } else {
      toastError('Você não selecionou nenhum arquivo!');
      setModalErro(true)
    }
  };

  const handleDocFaturamentoChange = async (event: any) => {
    const selectedFilesetFaturamento = event.target.files[0];
    if (selectedFilesetFaturamento) {
      if (selectedFilesetFaturamento.size > MAX_FILE_SIZE) {
        toastError('O tamanho do arquivo excede o limite permitido.');
        setModalErro(true);
      } else {
        try {
          const response = await updateSellerDocuments({
            type: "comprovante_endereco",
            file: selectedFilesetFaturamento
          });


        if (response.success) {
          setValue("faturamento", selectedFilesetFaturamento);
          toastSuccess("Declaração de faturamento salvo com sucesso")
          setModalsucesso(true)
        }
      } catch (err) {

        toastError("Erro para salvar o documento. Tente novamente mais tarde")

      }
      }

    } else {
      toastError('Você não selecionou nenhum arquivo!');
      setModalErro(true)
    }
  };




  return (
    <React.Fragment>
      <Stack spacing={2}>
        <Row>
          <TextCenter>

            <CustomText type={"h1"}>
              Documentos de indentificação
            </CustomText>
          </TextCenter>
        </Row>
        
        <Row>
            <TextCenter>

              <CustomText type={"h6"} >
                Documentos necessários para manter a sua segurança dentro da plataforma
              </CustomText>
            </TextCenter>

        </Row>

        <GridContainer>
          <GridItem>
            <RenderFileInput
              id={"comprovante_endereco"}
              name={"Comprovante de Endereço"}
              value={formData.comprovante_endereco}
              onChange={handleDocEnderecoChange}
              accept=".pdf, .jpeg, .jpg, .png"
              required={true}
            />
          </GridItem>
          <GridItem>
            <RenderFileInput
              id={"faturamento"}
              name={"Declaração de Faturamento"}
              value={formData.faturamento}
              onChange={handleDocFaturamentoChange}
              accept=".pdf, .jpeg, .jpg, .png"
              required={false}
            />
          </GridItem>
          <GridItem>
            <RenderImageInput
              id={"foto_doc_pessoal_frente"}
              name={"Foto do Documento Pessoal (Frente)"}
              value={formData.foto_doc_pessoal_frente}
              onChange={handleFotoDocPessoalFrenteChange}
            />
          </GridItem>
          <GridItem>
            <RenderImageInput
              id={"foto_doc_pessoal_verso"}
              name={"Foto do Documento Pessoal (Verso)"}
              value={formData.foto_doc_pessoal_verso}
              onChange={handleFotoDocPessoalVersoChange}
            />
          </GridItem>
          <GridItem>
            <RenderImageInput
              id={"foto_rosto_frente"}
              name={"Selfie com o Documento"}
              value={formData.foto_rosto_frente}
              onChange={handleFotoRostoFrenteChange}
            />
          </GridItem>

          {hasCnpj &&
            <React.Fragment>

              <GridItem>
                <RenderFileInput
                  id={"cnpj_doc_mei"}
                  name={"Documento CNPJ"}
                  value={formData.cnpj_doc_mei}
                  onChange={handleCnpjDocMEIChange}
                  accept=".pdf"
                  required={false} 

                />
              </GridItem>
              <GridItem>
                <RenderFileInput
                  id={"cnpj_certidao"}
                  name={"Contrato Social ou Certificado de micro empreendedor)"}
                  value={formData.cnpj_certidao}
                  onChange={handleCnpjCertidaoChange}
                  accept=".pdf"
                  required={true}
                />
              </GridItem>
            </React.Fragment>

          }
        </GridContainer>
      </Stack>
    </React.Fragment>

  )
}

export default PersonalDocumentsStep;
