import React from "react";
import { FaFilePdf, FaCheck, FaFileImage, FaArrowRight, FaPaperclip } from 'react-icons/fa';
import {
    FormLabel,
    Icon
} from "@chakra-ui/react";
import { RenderImgContainer, 
    RenderImgButtonLabel, 
    TitleLabel, 
    RenderImgIconStatus,
    SubtitleLabel,
    TitleLabelContainer } from "../styles";

const RenderFileInput = ({ id, name, value, onChange, accept, required }) => {



    const fileNamel = value instanceof File ? value.name : value;
    const pdfUrl = value instanceof File ? URL.createObjectURL(value) : fileNamel !== '' ? `${process.env.REACT_APP_API_URL_IMAGE}/storage/` + fileNamel : '';

    return (
        <RenderImgContainer fileName={fileNamel}>
            <RenderImgIconStatus>
                {fileNamel ?
                    <Icon as={FaCheck} boxSize={9} color="green" /> :
                    <Icon as={FaFilePdf} boxSize={9} />}
            </RenderImgIconStatus>

            <TitleLabelContainer>
                <TitleLabel>{name}</TitleLabel>
                <SubtitleLabel>Formatos aceitos de arquivos: pdf (limite 2MB)</SubtitleLabel>
            </TitleLabelContainer>

            <RenderImgButtonLabel
                htmlFor={id}
            >
                <FaPaperclip />
                {fileNamel ? "Editar" : "Selecionar"}
            </RenderImgButtonLabel>
            <input
                id={id}
                type="file"
                accept={accept}
                style={{ display: "none" }}
                onChange={onChange}
                required={required}
            //   disabled={status !== "Pedir"}
            />


        </RenderImgContainer>
    );

}

export default RenderFileInput;