import styled from 'styled-components';

export const ButtonStyles = styled.button<{variant, isFullWidth?: boolean}>`
  /* Estilos padrão */
  padding: 15px 20px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out, border 0.1s ease-in-out;
  width: ${props => (props.isFullWidth ? '100%' : 'auto')};  
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  /* Variantes */
  ${props => {
    switch (props.variant) {
      case 'primary':
        return `
          background-color: #00C26E;
          color: white;
          border: none;

          &:hover {
            background-color: #00C26E;
          }
        `;
      case 'secondary':
        return `
          background-color: transparent;
          color: #5505B4;
          border: 1px solid #0D3323;

          &:hover {
            background-color: #0D3323;
            color: white;
          }
        `;
      case 'tertiary':
        return `
          background-color: transparent;
          color: #FFF;
          border: none;

          &:hover {
            background-color: transparent;
            color: #FFF;
          }
        `;
      default:
        return `
          background-color: #0D3323;
          color: white;
          border: none;
        `;
    }
  }}
`;